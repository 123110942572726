@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 20px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  background-image: url("./assets/images/backgroundHome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.div-square {
  width: 40px;
  height: 40px;
  border: 1px solid #008345;
  border-radius: 8px;
  font-size: 26px;
  color: #008345;
  justify-content: center;
  align-items: center;
}
.div-square-inside {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.green-color {
  color: #008345;
  font-weight: bold;
}
.black-color {
  color: #000000;
  font-weight: bold;
}
.div-result {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  margin: 10px 0;
  padding: 5px 20px;
}
.div-member {
  background-color: #ffffff;
  padding: 10px 20px;
  height: calc(100vh - 280px);
}
.label-div-member {
  text-align: center;
  font-size: 24px;
}
.btn-reset {
  background: #008345;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(15, 31, 47, 0.25);
  border-radius: 40px;
  width: 100%;
  cursor: pointer;
  height: 44px;
  font-size: 20px;
  color: #ffffff;
}
select option {
  color: #008345;
}
select option:first-child {
  color: #ddd !important;
}
.div-bg-login {
  background-color: #d8fbe9;
  height: 100vh;
  position: relative;
}
.img-car {
  width: 30vw;
  object-fit: cover;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}
.img-balloon {
  width: 20vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.img-logo {
  width: 250px;
  object-fit: contain;
  margin-bottom: 30px;
  margin-bottom: 30px;
}
.div-center-login {
  position: absolute;
  top: calc((100vh - 50vh) / 2);
  left: calc((100vw - 70vw) / 2);
  text-align: center;
  perspective: 2000px;
}
.div-parent {
  position: relative;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  width: 70vw;
  height: 50vh;
}
.div-form-title {
  width: 100%;
  height: 50vh;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.div-form-login {
  width: 100%;
  height: 50vh;
  border: 1px solid #42957d;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.div-form-title p {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.1em;
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
}
.control {
  position: relative;
  margin: 0 !important;
}
.input-email ~ .input-email-icon {
  background-image: url("../src/assets/images/mailIcon.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 22px;
  height: 20px;
  position: absolute;
  left: 14px;
  top: 7px;
}
.input-password ~ .input-password-icon {
  background-image: url("../src/assets/images/padlockIcon.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  width: 22px;
  height: 20px;
  position: absolute;
  left: 14px;
  top: 7px;
}
.input-otp {
  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 40px;
}
.input-otp .ReactInputVerificationCode__container {
  margin: 0 auto;
}
.input-otp .ReactInputVerificationCode__item {
  position: relative;
  font-weight: 500;
  font-size: 40px;
}
.input-otp .ReactInputVerificationCode__item {
  box-shadow: none;
}
.input-otp .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  border-bottom: 1px solid #000000;
}
.input-otp .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #ebebeb;
  transition: background-color 0.2s ease-out;
}
.input-otp .ReactInputVerificationCode__item.is-active:after {
  background-color: var(--hbl-dark-green);
}
.footer {
  background-color: #fff;
  padding: 10px 24px;
  font-size: 12px;
  background-color: rgb(37, 101, 50);
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 100vw !important;
    padding: 0 10px;
  }
  .div-center-login {
    left: calc((100vw - 60vw) / 2);
  }
  .div-parent {
    width: 60vw;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    width: 1400px;
  }
}

/* @layer components {
  .container {
    max-width: 96em;
    @apply px-4 sm:px-8 lg:px-14 xl:px-14 min-h-screen m-auto w-full
  }
} */
